<template>
  <main>
    <!-- Form Filter -->
    <header class="mb-1">
      <b-row>
        <!-- Page Limit -->
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page {{ currentPage }} </label>
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
          </b-form-group>
        </b-col>
        <!-- / -->
        <!-- Sort By -->
        <b-col md="4" sm="6" class="my-1">
          <b-form-group :label="$t('Sort')" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="sortBySelect" class="mb-0">
            <b-input-group size="sm">
              <b-form-select id="sortBySelect" v-model="sortBy" size="sm" :options="sortOptions" class="w-75">
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- / -->
        <!-- Filter Search -->
        <b-col md="6" sm="2" class="my-1">
          <b-form-group :label="$t('Filter')" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" size="sm">
            <b-input-group size="sm">
              <b-form-input id="filterInput" v-model="filter" type="search" size="sm" class="w-75"  />
              <b-input-group-append>
                <b-button size="sm" :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- / -->
      </b-row>
    </header>
    <!-- /form filter -->
    <b-table
      striped
      small
      hover
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :items="datas"
      :fields="fieldsOwner"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(harga_dasar)="{ item }"> Rp. {{ formatRupiah(item.harga_dasar) }} </template>
      <!-- Check box for show detail -->
      <template #cell(show_detail)="row">
        <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
          <span class="vs-checkbox">
            <span class="vs-checkbox--check">
              <i class="vs-icon feather icon-check" />
            </span>
          </span>
          <span class="vs-label">{{ row.detailsShowing ? "Hide" : "Show" }}</span>
        </b-form-checkbox>
      </template>
      <!-- / -->
      <!-- Detail -->
      <template #row-details="{ toggleDetails, item }">
        <!-- <b-card> -->
        <b-row class="mb-1">
          <b-col cols="3">
            <table border="3">
              <tr>
                <th>No</th>
                <th>Varian</th>
              </tr>
              <tr v-for="(data, i) in item.varian_item" :key="i">
                <td>{{ i + 1 }}</td>
                <td>
                  {{ data.varian ? data.varian : "-" }}
                </td>
              </tr>
            </table>
          </b-col>
          <b-col cols="8">
            <table border="3">
              <tr>
                <!-- <th>No</th> -->
                <th>Satuan Dari</th>
                <th>Nilai</th>
                <!-- <th>Satuan Barang</th> -->
              </tr>
              <tr v-for="(satuan, i) in item.konversi" :key="i">
                <!-- <td>{{ i + 1 }}</td> -->
                <!-- <td>{{ satuan.no }}</td> -->
                <td>1 {{ satuan.satuan ? satuan.satuan.satuan : "-" }}</td>
                <td>
                  {{ formatRupiah(satuan.nilai) }}
                </td>
                <!-- <td>
                    {{ formatRupiah(satuan.barang.satuan.satuan) }}
                  </td> -->
              </tr>
            </table>
          </b-col>
        </b-row>
        <b-button size="sm" variant="outline-danger" @click="toggleDetails" class="mb-1"> Hide </b-button>
        <!-- </b-card> -->
      </template>
      <template #cell(no)="{ index }">
        {{ index + 1 }}
      </template>
      <template #cell(jenis)="{ item }">
        {{ item.jenis == 1 ? "Rokok" : "Non-Rokok" }}
      </template>
      <template #cell(harga_jual)="{ item }">
        {{ item.harga_jual && item.harga_jual.length > 0 ? formatRupiah(item.harga_jual[0].harga) : 0 }}
      </template>

      <template #cell(id_satuan)="{ item }">
        {{ item.satuan ? item.satuan.satuan : "-" }}
      </template>
      <template #cell(action)="{ item }">
        <section class="d-flex justify-content-center">
          <b-button v-if="allowUpdate($route.meta.module)" :id="`edit-btn-${item.id}`" size="sm" @click.prevent="$router.push(`/barang/edit/${item.id}`)" class="mr-1" variant="outline-info">
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-tooltip :target="`edit-btn-${item.id}`">Edit</b-tooltip>

          <b-button v-if="allowDelete($route.meta.module)" :id="`delete-btn-${item.id}`" size="sm" @click.prevent="deleteSingle(item.id)" class="mr-1" variant="outline-danger">
            <feather-icon icon="TrashIcon" />
          </b-button>
          <b-tooltip :target="`delete-btn-${item.id}`">Hapus</b-tooltip>
        </section>
      </template>
    </b-table>
    <b-row>
      <b-col cols="12">
        <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage" align="center" size="sm" class="my-0" />
      </b-col>
    </b-row>
  </main>
</template>
<script>
import { BCard, BFormCheckbox, BFormInput, BInputGroup, BInputGroupAppend, BFormGroup, BFormSelect, BPagination, BTable, BButton, BRow, BCol, BTooltip } from "bootstrap-vue";

export default {
  props: {
    datas: {
      required: true,
    },
    total: {
      required: true,
    },
  },
  watch: {
    currentPage(val) {
      this.$emit("load", val);
    },
  },
  components: {
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BTable,
    BButton,
    BTooltip,
    BRow,
    BCard,
    BCol,
    BFormCheckbox,
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  data() {
    return {
      pageOptions: [10, 50, 100],
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      perPage: 10,
      totalRows: this.datas.length,
      currentPage: 1,
      fields: [
        { key: "show_detail", label: "#" },
        { key: "no", label: "No", sortable: true },
        { key: "nama", label: "Nama Barang", sortable: true },
        { key: "kode", label: "Kode" },
        { key: "varian", label: "Varian" },
        // { key: "harga_jual", label: "Harga Jual", sortable: true },
        { key: "id_satuan", label: "Satuan" },
        { key: "action", label: "#" },
      ],
      fieldsOwner: [
        { key: "show_detail", label: "#" },
        { key: "no", label: "No", sortable: true },
        { key: "nama", label: "Nama Barang", sortable: true },
        { key: "kode", label: "Kode" },
        // { key: "varian", label: "Varian" },
        { key: "harga_dasar", label: "Harga Dasar", sortable: true },
        { key: "id_satuan", label: "Satuan" },
        { key: "action", label: "#" },
      ],
    };
  },
  methods: {
    showConsole(data) {},
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    deleteSingle(id) {
      this.$bvModal
        .msgBoxConfirm("Anda yakin akan menghapus barang ini? Stok yang didalamnya akan hilang!", {
          title: "Konfirmasi Penghapusan",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          // this.boxTwo = value
          this.$store
            .dispatch("barang/save", [
              {
                id,
                fungsi: 1,
              },
            ])
            .then(() => this.$emit("refreshData"))
            .catch((e) => this.displayError(e));
        });
    },
  },
};
</script>
